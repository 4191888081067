import React from "react";

export default function NotFound() {
  return (
    <div
      style={{
        display: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: 10,
        height: "100vh",
      }}
    >
      <p>Page Not Found</p>
    </div>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTEpCxlWZJ9SwGYpcbVTZok2UaPE3VgxI",
  authDomain: "verify-license.firebaseapp.com",
  projectId: "verify-license",
  storageBucket: "verify-license.appspot.com",
  messagingSenderId: "609538403018",
  appId: "1:609538403018:web:d7b61d421a21d5f808e233",
  measurementId: "G-N0RZJBX3G5",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);

export const auth = getAuth(app);

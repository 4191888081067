import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, Alert } from "react-bootstrap";
import { collection, query, where, getDocs } from "firebase/firestore";
import { HiOutlineRefresh } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import AppBar from "../../components/AppBar";
import { db } from "../../firebaseConfig";
import "./index.css";

export default function VerifyLicense() {
  const navigate = useNavigate();
  const [cnic, setCnic] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");
  const [recaptchaNumber, setRecaptchaNumber] = useState("");

  const generateRandomNumber = () => {
    const randomNum = Math.floor(Math.random() * 1000000); // Generates a random number between 0 and 99
    setRecaptchaNumber(randomNum);
  };
  useEffect(() => {
    generateRandomNumber();
  }, []);

  // const fetchLicensesByCriteria = async (licenseNumber, cnic) => {

  // };

  const searchLicense = async () => {
    if (cnic === "") {
      setVariant("danger");
      setMessage("CNIC is required!");
    } else if (licenseNumber === "") {
      setVariant("danger");
      setMessage("License number is required!");
    } else if (captcha === "") {
      setVariant("danger");
      setMessage("Captcha is required!");
    } else if (captcha != recaptchaNumber) {
      setVariant("danger");
      setMessage("Kindly enter correct captcha number");
    } else {
      try {
        // Create a query based on licenseNumber and cnic
        const q = query(
          collection(db, "licenses"),
          where("licenseNumber", "==", licenseNumber),
          where("cnic", "==", cnic)
        );
        const dbLicense = [];
        // Execute the query
        const querySnapshot = await getDocs(q);
        // Loop through the documents
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          dbLicense.push({ ...doc.data(), id: doc.id });
        });
        if (dbLicense.length > 0) {
          navigate("/license-details", { state: { ...dbLicense[0] } });
        } else {
          setVariant("danger");
          setMessage(
            "There is no license with this cnic number and license number"
          );
        }
        setTimeout(() => {
          setVariant("");
          setMessage("");
        }, 3000);
        // navigate("/license-details");
      } catch (error) {
        setVariant("danger");
        setMessage("Error fetching licenses");
        // console.error("Error fetching licenses: ", error);
      }
      // setVariant("");
      // setMessage("");
      // navigate("/license-details");
    }
  };

  return (
    <div>
      <AppBar />
      <div className="mainDiv">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xs={12}>
              <h2>Verify License</h2>
              {variant !== "" && <Alert variant="danger">{message}</Alert>}

              <div className="inputDiv">
                <label>Enter CNIC*</label>
                <Form.Control
                  size="md"
                  type="number"
                  placeholder="CNIC without dashes."
                  value={cnic}
                  onChange={(e) => {
                    setCnic(e.target.value);
                    setVariant("");
                  }}
                />
              </div>
              <div className="inputDiv">
                <label>License Number*</label>
                <Form.Control
                  size="md"
                  type="number"
                  placeholder="Please Enter License Number"
                  value={licenseNumber}
                  onChange={(e) => {
                    setLicenseNumber(e.target.value);
                    setVariant("");
                  }}
                />
              </div>
              <div className="inputDiv">
                <label>Captcha*</label>
                <Form.Control
                  size="md"
                  type="number"
                  placeholder="Enter Captcha"
                  value={captcha}
                  onChange={(e) => {
                    setCaptcha(e.target.value);
                    setVariant("");
                  }}
                />
              </div>
              <div className="recaptchaSec">
                <div>{recaptchaNumber}</div>
                <button onClick={generateRandomNumber}>
                  <HiOutlineRefresh color="#000" size={20} />
                </button>
              </div>
              <div className="btnDiv">
                <button className="verifyBtn" onClick={searchLicense}>
                  Verify Licence
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

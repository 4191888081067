import React from "react";
import MainRouter from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return <MainRouter />;
}

export default App;

import React, { useEffect, useState } from "react";
import { Button, ListGroup, Card, Container, Row, Col } from "react-bootstrap";
import DataTable from "../../components/Table";
import { useNavigate } from "react-router-dom";
import AppBar from "../../components/AppBar";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";

export default function Dashboard() {
  const navigate = useNavigate();
  const [licenses, setLicenses] = useState([]);
  const fetchLicenses = async () => {
    try {
      const dbLicenses = [];
      const querySnapshot = await getDocs(collection(db, "licenses"));
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        dbLicenses.push({ ...doc.data(), id: doc.id });
      });
      setLicenses([...dbLicenses]);
    } catch (error) {
      console.error("Error fetching licenses: ", error);
    }
  };
  useEffect(() => {
    fetchLicenses();
  }, []);

  return (
    <div>
      <AppBar />
      <div style={{ marginTop: 40 }}>
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            <Button
              variant="success"
              className="addBtn"
              onClick={() => navigate("/add-license")}
            >
              Add License
            </Button>
          </div>

          {licenses.length === 0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              There is no license available
            </div>
          ) : (
            <DataTable
              data={licenses}
              itemsPerPage={5}
              fetchLicenses={fetchLicenses}
            />
          )}
        </Container>
      </div>
    </div>
  );
}

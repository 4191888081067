import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppBar from "../../components/AppBar";
import { auth } from "../../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

export default function EditLicense() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");

  const updateicense = () => {};

  return (
    <div>
      <AppBar />
      <div className="mainDiv">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xs={12}>
              <h2>Login</h2>
              {variant !== "" && <Alert variant="danger">{message}</Alert>}

              <div className="inputDiv">
                <label>Email</label>
                <Form.Control
                  size="md"
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setVariant("");
                  }}
                />
              </div>
              <div className="inputDiv">
                <label>Password</label>
                <Form.Control
                  size="md"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setVariant("");
                  }}
                />
              </div>
              <div className="btnDiv">
                <button className="verifyBtn" onClick={updateicense}>
                  Update
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

import React from "react";
import { Button, ListGroup, Card, Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import AppBar from "../../components/AppBar";

export default function LicenseDetails() {
  const location = useLocation();
  // const {
  //   allowedVehicles,
  //   city,
  //   cnic,
  //   driverName,
  //   fatherHusbandName,
  //   id,
  //   issueDate,
  //   licenseNumber,
  //   photoUrl,
  //   state,
  //   validFrom,
  //   validTo,
  // } = location.state;

  // Use the license data received from the previous route
  // console.log(location.state);
  return (
    <div>
      <AppBar />
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <Container>
          <Row className="justify-content-center">
            {location.state ? (
              <Col lg={6} md={8} xs={12}>
                <Card style={{ padding: 20 }}>
                  <img src={location.state.photoUrl} className="licenseImge" />
                  <div className="detailsHeading">Driver Information</div>
                  <div className="detailsRow">
                    <b>CNIC: </b>
                    <div className="detailsRowValue">{location.state.cnic}</div>
                  </div>
                  <div className="detailsRow">
                    <b>License Number: </b>
                    <div className="detailsRowValue">
                      {location.state.licenseNumber}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>Driver Name: </b>
                    <div className="detailsRowValue">
                      {location.state.driverName}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>Father/Husband Name: </b>
                    <div className="detailsRowValue">
                      {location.state.fatherHusbandName}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>Allowed Vehicles: </b>
                    <div className="detailsRowValue">
                      {location.state.allowedVehicles}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>State: </b>
                    <div className="detailsRowValue">
                      {location.state.state}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>City: </b>
                    <div className="detailsRowValue">{location.state.city}</div>
                  </div>
                  <div className="detailsHeading">License Duration</div>
                  <div className="detailsRow">
                    <b>Issue Date: </b>
                    <div className="detailsRowValue">
                      {location.state.issueDate}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>Valid From: </b>
                    <div className="detailsRowValue">
                      {location.state.validFrom}
                    </div>
                  </div>
                  <div className="detailsRow">
                    <b>Valid To: </b>
                    <div className="detailsRowValue">
                      {location.state.validTo}
                    </div>
                  </div>
                </Card>
              </Col>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                There is no license available
              </div>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}

import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo1.png";
import "./index.css";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";

function AppBar() {
  const navigate = useNavigate();
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand onClick={() => navigate("/")}>
          <img src={Logo} className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* <Nav className="me-auto">

          </Nav> */}
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link href="#howitworks" className="navLink">
              How it works
            </Nav.Link>
            <Nav.Link href="#aboutus" className="navLink">
              About Us
            </Nav.Link>
            <Nav.Link href="#faq" className="navLink">
              FAQ
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppBar;

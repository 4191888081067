// This is a React Router v5 app
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useRouteMatch,
  Routes,
} from "react-router-dom";
import LicenseDetails from "../pages/LicenseDetails";
import VerifyLicense from "../pages/VerifyLicense";
import EditLicense from "../pages/EditLicense";
import AddLicense from "../pages/AddLicense";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Login from "../pages/login";

export default function BasicRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<VerifyLicense />} />
        <Route path="/license-details" element={<LicenseDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/edit-license" element={<EditLicense />} />
        <Route path="/add-license" element={<AddLicense />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

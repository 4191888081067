import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AppBar from "../../components/AppBar";
import { auth, db, storage } from "../../firebaseConfig";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";

export default function AddLicense() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    photoUrl: "",
    cnic: "",
    licenseNumber: "",
    driverName: "",
    fatherHusbandName: "",
    allowedVehicles: "",
    state: "",
    city: "",
    issueDate: "",
    validFrom: "",
    validTo: "",
  });
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (value, key) => {
    setData({ ...data, [key]: value });
    setVariant("");
    setMessage("");
  };

  const handleUpload = (e) => {
    try {
      var image = e.target.files[0];
      console.log(image);
      const storageRef = ref(storage, `images/${image.name}`);
      uploadBytes(storageRef, image).then((snapshot) => {
        getDownloadURL(storageRef)
          .then((url) => {
            console.log(url);
            setData({ ...data, photoUrl: url });
          })
          .catch((error) => {
            // Handle any errors
          });
        console.log("Uploaded a blob or file!");
      });
    } catch (error) {
      console.lg(error);
    }
  };

  const addLicense = async () => {
    let isValid = true;
    // Loop through each key in the data object
    for (let key in data) {
      // Check if the value is empty
      if (!data[key]) {
        isValid = false;
        setVariant("danger");
        setMessage(`${key} is required`);
        break; // Exit the loop early if any field is empty
      }
      // If all fields are valid, proceed with adding license
    }
    if (isValid) {
      // Add your logic for adding license here
      try {
        // Add data to Firestore
        const docRef = await addDoc(collection(db, "licenses"), data);
        setVariant("success");
        setMessage("license added successfuly");
        setData({
          photoUrl: "",
          cnic: "",
          licenseNumber: "",
          driverName: "",
          fatherHusbandName: "",
          allowedVehicles: "",
          state: "",
          city: "",
          issueDate: "",
          validFrom: "",
          validTo: "",
        });
        setTimeout(() => {
          setVariant("");
          setMessage("");
        }, 3000);
        console.log("Document written with ID: ", docRef.id);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  return (
    <div>
      <AppBar />
      <div style={{ marginTop: 50, paddingBottom: 50 }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xs={12}>
              <h2>Add License</h2>
              {variant !== "" && <Alert variant={variant}>{message}</Alert>}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleUpload(e)}
                style={{ display: "none" }}
                id="file-input"
              />
              <label htmlFor="file-input">
                <div
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                    border: "2px dashed #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  {data.photoUrl ? (
                    <img
                      src={data.photoUrl}
                      alt="Preview"
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <span>Upload Image</span>
                  )}
                </div>
              </label>
              <div className="inputDiv">
                <label>CNIC</label>
                <Form.Control
                  size="md"
                  type="number"
                  placeholder="Enter CNIC Number"
                  value={data.cnic}
                  onChange={(e) => handleChange(e.target.value, "cnic")}
                />
              </div>
              <div className="inputDiv">
                <label>License Number</label>
                <Form.Control
                  size="md"
                  type="text"
                  placeholder="Enter License Number"
                  value={data.licenseNumber}
                  onChange={(e) =>
                    handleChange(e.target.value, "licenseNumber")
                  }
                />
              </div>
              <div className="inputDiv">
                <label>Driver Name</label>
                <Form.Control
                  size="md"
                  type="text"
                  placeholder="Enter Driver Name"
                  value={data.driverName}
                  onChange={(e) => handleChange(e.target.value, "driverName")}
                />
              </div>
              <div className="inputDiv">
                <label>Father/Husband Name</label>
                <Form.Control
                  size="md"
                  type="text"
                  placeholder="Enter Father/Husband Name"
                  value={data.fatherHusbandName}
                  onChange={(e) =>
                    handleChange(e.target.value, "fatherHusbandName")
                  }
                />
              </div>
              <div className="inputDiv">
                <label>Allowed Vehicles</label>
                <Form.Control
                  size="md"
                  type="text"
                  placeholder="Enter Allowed Vehicles"
                  value={data.allowedVehicles}
                  onChange={(e) =>
                    handleChange(e.target.value, "allowedVehicles")
                  }
                />
              </div>
              <div className="inputDiv">
                <label>State</label>
                <Form.Control
                  size="md"
                  type="text"
                  placeholder="Enter State Name"
                  value={data.state}
                  onChange={(e) => handleChange(e.target.value, "state")}
                />
              </div>
              <div className="inputDiv">
                <label>City</label>
                <Form.Control
                  size="md"
                  type="text"
                  placeholder="Enter City Name"
                  value={data.city}
                  onChange={(e) => handleChange(e.target.value, "city")}
                />
              </div>
              <div className="inputDiv">
                <label>Issue Date</label>
                <Form.Control
                  size="md"
                  type="date"
                  placeholder=""
                  value={data.issueDate}
                  onChange={(e) => handleChange(e.target.value, "issueDate")}
                />
              </div>
              <div className="inputDiv">
                <label>Valid From</label>
                <Form.Control
                  size="md"
                  type="date"
                  placeholder=""
                  value={data.validFrom}
                  onChange={(e) => handleChange(e.target.value, "validFrom")}
                />
              </div>
              <div className="inputDiv">
                <label>Valid To</label>
                <Form.Control
                  size="md"
                  type="date"
                  placeholder=""
                  value={data.validTo}
                  onChange={(e) => handleChange(e.target.value, "validTo")}
                />
              </div>
              <div className="btnDiv">
                <button className="verifyBtn" onClick={addLicense}>
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

import { deleteDoc, doc } from "firebase/firestore";
import React, { useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { db } from "../../firebaseConfig";

const DataTable = ({ data, itemsPerPage, fetchLicenses }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentData = data.slice(startIndex, endIndex);

  const deleteLicenseById = async (licenseId) => {
    try {
      await deleteDoc(doc(db, "licenses", licenseId));
      fetchLicenses();
      console.log("License deleted successfully");
    } catch (error) {
      console.error("Error deleting license: ", error);
    }
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Driver Name</th>
            <th>F/H. Name</th>
            <th>CNIC</th>
            <th>License Number</th>
            <th>State</th>
            <th>City</th>
            <th>Issue Date</th>
            <th>Actions</th>
            {/* Add more table headers here */}
          </tr>
        </thead>
        <tbody className="table_body">
          {currentData.map((item, index) => (
            <tr key={index}>
              <td>{startIndex + index + 1}</td>
              <td>{item.driverName}</td>
              <td>{item.fatherHusbandName}</td>
              <td>{item.cnic}</td>
              <td>{item.licenseNumber}</td>
              <td>{item.state}</td>
              <td>{item.city}</td>
              <td>{item.issueDate}</td>
              <td>
                <button onClick={() => deleteLicenseById(item.id)}>
                  delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination>
          <Pagination.Prev
            onClick={() => handleClick(currentPage > 1 ? currentPage - 1 : 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handleClick(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() =>
              handleClick(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DataTable;
